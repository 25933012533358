<template>    
    <section class="hero services section-padding" data-scroll-index="1">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="intro text-center mb-40">
                        <h1 class="mb-10">Build Flexible, design-driven websites with ease.</h1>
                        <p class="fw-500"> We Convert PSD to XHTML, Wordpress, Joomla, osCommerce. Zen Cart and other CMS, Cart or Custom Module </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                	<div class="item mb-md50">
						<router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-1.png`" alt="WordPress" class="icon"> </router-link>
                        <h5>PSD To WordPress</h5>
                        <p>Starting From</p>
                        <h4>£ 100</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-2.png`" alt="Magento" class="icon"> </router-link>
                        <h5>PSD To Magento</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-3.png`" alt="Opencart" class="icon"> </router-link>
                        <h5>PSD To Opencart</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3 d-none">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-4.png`" alt="Joomla" class="icon"> </router-link>
                        <h5>PSD To Joomla</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-5.png`" alt="Drupal" class="icon"> </router-link>
                        <h5>PSD To Drupal</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-6.png`" alt="Psd To Html" class="icon"> </router-link>
                        <h5>PSD To HTML</h5>
                        <p>Starting From</p>
                        <h4>£ 100</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-7.png`" alt="Illustrator" class="icon"> </router-link>
                        <h5>AI To HTML</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-8.png`" alt="Fireworks" class="icon"> </router-link>
                        <h5>Fireworks To HTML</h5>
                        <p>Starting From</p>
                        <h4>£ 200</h4>
                    </div>                
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="item mb-md50">
                        <router-link :to="{hash:'#'}" class="logo" title="Click To Quote"> <img v-lazy="`${publicPath}images/icons/icon-10.png`" alt="Psd To Other" class="icon"> </router-link>
                        <h5>PSD To Other</h5>
                        <p>Covert Your PSD in any format</p>
                    </div>                
                </div>
            </div>
        </div>
    </section>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
